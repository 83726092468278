<script async setup lang="ts"></script>

<template>
  <ImagePreloader />
  <suspense>
    <router-view />
  </suspense>
</template>

<style scoped></style>

