<script setup lang="ts">
  import { useLayout } from "@/layout/composables/layout"
  import AppBreadcrumb from "./AppBreadcrumb.vue"

  const { onMenuToggle /*onProfileSidebarToggle, onConfigSidebarToggle*/ } =
    useLayout()

  // function showProfileSidebar() {
  //   onProfileSidebarToggle()
  // }
</script>

<template>
  <div class="layout-topbar">
    <div class="topbar-start">
      <Button
        type="button"
        class="topbar-menubutton p-trigger"
        @click="onMenuToggle">
        <i class="pi pi-bars"></i>
      </Button>

      <AppBreadcrumb class="topbar-breadcrumb"></AppBreadcrumb>
    </div>

    <div class="topbar-end lg:hidden">
      <ul class="topbar-menu">
        <!-- <li class="topbar-search">
          <IconField>
            <InputIcon class="pi pi-search" />
            <InputText
              type="text"
              placeholder="Search"
              class="w-48 sm:w-full" />
          </IconField>
        </li>
        <li class="ml-4">
          <Button
            icon="pi pi-cog"
            text
            rounded
            severity="secondary"
            @click="onConfigSidebarToggle"></Button>
        </li> -->
        <li class="topbar-profile">
          <img
            src="/images/FDC_Logo_slogan_noir.svg"
            style="width: 220px !important ; height: 40px !important" />
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
