import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"

//import BlockViewer from '@/components/BlockViewer.vue';
import "@/assets/styles.scss"
import "@/assets/tailwind.css"
import Aura from "@primevue/themes/aura"
import PrimeVue from "primevue/config"

import { useEquipeStore } from "@/store/EquipeStore"
import { useProduitStore } from "@/store/ProduitStore"
import { createPinia } from "pinia"
import piniaPluginPersistedState from "pinia-plugin-persistedstate"

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

const app = createApp(App)

app.use(pinia)

import { definePreset } from "@primevue/themes"
const MyPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: "{red.50}",
      100: "{red.100}",
      200: "{red.200}",
      300: "{red.300}",
      400: "{red.400}",
      500: "#ad0d0a",
      600: "{red.600}",
      700: "{red.700}",
      800: "{red.800}",
      900: "{red.900}",
      950: "{red.950}",
    },
  },
})
async function initializeApp() {
  try {
    await Promise.all([
      useProduitStore().getGamme(),
      useEquipeStore().getEquipe(),
    ])

    app.use(router)
    app.use(PrimeVue, {
      theme: {
        preset: MyPreset,
        options: {
          darkModeSelector: ".app-dark",
        },
      },
    })
    app.mount("#app")
  } catch (error) {
    console.error("Failed to initialize app:", error)
  }
}

initializeApp()
// Promise.all([useProduitStore().getGamme(), useEquipeStore().getEquipe()]).then(
//   () => {
//     app.use(router)
//     app.use(PrimeVue, {
//       theme: {
//         preset: MyPreset,
//         options: {
//           darkModeSelector: ".app-dark",
//         },
//       },
//     })
//     app.mount("#app")
//   },
// )

