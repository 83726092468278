<template>
  <div>
    <!-- Les balises <link> seront ajoutées ici -->
  </div>
</template>

<script setup lang="ts">
  const images = import.meta.glob("/public/images/**/*.{jpg,png,webp}")

  const links = Object.keys(images).map((path) => {
    const link = document.createElement("link")
    link.rel = "preload"
    link.as = "image"
    link.href = path.replace("/public", "")
    return link
  })

  // Ajouter les balises <link> à la tête du document
  links.forEach((link) => document.head.appendChild(link))
</script>
